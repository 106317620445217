























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import Organization, {OrganizationDocument} from '@/models/organization';
import { ErrorAPI, MerchantAPI } from '@/api';

@Component
export default class OrganizationForm extends Vue {
  readonly MaximumDocumentsCount: number = 20;
  readonly MaximumDocumentSize: number = 15;
  readonly UniqueFileTypeSpecifier: string[] = [
    'image/jpg', // JPG
    'image/jpeg', // JPEG, JFIF
    'image/jpe', // JPE
    'image/png', // PNG
    'image/tiff', // TIFF
    'application/msword', // DOC
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
    'text/plain', // TXT
    'application/vnd.ms-excel', // XLS
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
    'application/pdf', // PDF
  ];

  private organization: Organization = new Organization();
  private errors = new ErrorAPI();
  private isLoading: boolean = false;
  private exists: boolean = false;

  private documents: OrganizationDocument[] = [];
  private files: File | null = null;
  private isUploading: boolean = false;

  private mounted() {
    MerchantAPI.organizationFind().then((org: Organization) => {
      this.exists = true;
      this.organization = org;
      this.documents = org.documents;
    }).catch((err: any) => {
      if (err.response !== undefined && err.response.status === 404) {
        this.exists = false;
        return;
      }

      throw err;
    });
  }

  private valueRegex(event: any, regex: string) {
    event = event ?? window.event;
    const re = new RegExp(regex);
    const clipboardData = event.clipboardData || window.clipboardData;
    let str: string;

    if (clipboardData !== undefined) {
      str = clipboardData.getData('Text');
    } else {
      str = String.fromCharCode(event.which ?? event.keyCode);
    }

    if (!re.test(str)) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  private toUpperCase(value: string) {
    return value.toUpperCase();
  }

  private onSubmit() {
    return this.exists ? this.onUpdate() : this.onCreate();
  }

  private onCreate() {
    this.errors.clear();

    if (!this.validate()) {
      return false;
    }

    this.isLoading = true;

    MerchantAPI.organizationCreate(this.organization)
      .then((org: Organization) => {
        this.$buefy.toast.open({
          message: `<b>${this.$t('OrganizationCreated')}</b>`,
          position: 'is-bottom',
          type: 'is-success',
        });
        this.organization = org;
        this.exists = true;
    }).catch((err: any) => {
      if (err.response === undefined) {
        this.$buefy.toast.open({
          message: `<b>${this.$t('NetworkError')}</b>`,
          position: 'is-bottom',
          type: 'is-danger',
        });

        return
      }

      if (err.response.status === 500) {
        this.$buefy.toast.open({
          message: `<b>${err.response.data.message}</b>`,
          position: 'is-bottom',
          type: 'is-danger',
        });

        return;
      }

      this.errors.put(err.response.data ?? new Map());
    }).finally(() => { this.isLoading = false; });
  }

  private onUpdate() {
    this.errors.clear();

    if (!this.validate()) {
      return false;
    }

    this.isLoading = true;

    MerchantAPI.organizationUpdate(this.organization)
      .then((organization: Organization) => {
        this.$buefy.toast.open({
          message: `${this.$t('OrganizationUpdated')}: <b>#${organization.id}</b>`,
          position: 'is-bottom',
          type: 'is-success',
        });
        this.organization = organization;
        this.exists = true;
      }).catch((err: any) => {
        this.errors.put(err.response.data ?? new Map());
      }).finally(() => { this.isLoading = false; });
  }

  private onUpload() {
    const hidden = this.$refs['js__hidden-download'];
    if (hidden === undefined) {
      return;
    }

    // @ts-ignore
    if (hidden.files.length !== 1) {
      return;
    }

    // @ts-ignore
    const file = hidden.files[0];
    // @ts-ignore
    hidden.value = null;

    if (this.organization.id === null) {
      return;
    }

    if (this.documents.length >= this.MaximumDocumentsCount) {
      this.$buefy.toast.open({
        duration: 2000,
        message: this.$t('MaxFilesMsg').toString(),
        position: 'is-bottom',
        type: 'is-danger'
      });

      return;
    }

    if(!this.UniqueFileTypeSpecifier.includes(file.type)) {
      this.$buefy.toast.open({
        duration: 2000,
        message: this.$t('FileNotSupported').toString(),
        position: 'is-bottom',
        type: 'is-danger'
      });

      return;
    }

    if (file.size > (this.MaximumDocumentSize * 1024 * 1024)) {
      this.$buefy.toast.open({
        duration: 2000,
        message: `${this.$t('UplMaxSize')}: ${this.MaximumDocumentSize}MB ${this.$t('or')} ${this.MaximumDocumentSize * 1024}KB`,
        position: 'is-bottom',
        type: 'is-danger'
      });

      return;
    }

    this.isUploading = true;
    MerchantAPI.organizationUploadFile(this.organization.id, file)
        .then(res => {
          this.documents.push(res);
        }).catch(err => {
          if (err.response !== undefined) {
            const res = err.response;
            if (res.status === 422) {
              this.$buefy.toast.open({
                duration: 2000,
                message: this.$t('UploadError').toString(),
                position: 'is-bottom',
                type: 'is-danger'
              });
            }

            return;
          }
        }).finally(() => {
          this.isUploading = false;
        });
  }

  private onDelete(path: string) {
    const id = this.organization.id;
    if (id === null) {
      return;
    }

    const uuid = path.split('/')[1];
    this.$buefy.dialog.confirm({
      title: this.$t('DeletingDocument').toString(),
      message: this.$t('DeleteFileConfirm').toString(),
      confirmText: 'Delete',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        MerchantAPI.organizationDeleteFile(id, uuid)
            .then(() => {
              this.documents = this.documents.filter(d => d.path !== path);
            })
            .catch(err => {
              this.$buefy.toast.open({
                duration: 2000,
                message: `${this.$t('CanNotDeleteFile')}: ${err.toString()}`,
                position: 'is-bottom',
                type: 'is-danger'
              });
            });
      },
    });
  }

  private validate() {
    const form = this.$refs['vuejs-form-data'] as HTMLFormElement;
    if (form.checkValidity && !form.checkValidity()) {
      const elements = [...form.elements] as HTMLElement[];
      elements.forEach((e) => e.focus());
      // form.reportValidity();
      return false;
    }

    return true;
  }
}
